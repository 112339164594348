/*
  Return templates

  const error = {
    success: false,
    errorCode: "",
    errorType: "",
    errorMessage: ""
  };

  const success = {
    success: true,
    message: "",
    result: "",
    player: "" // Optional
  };
*/

/*
  error_id:
    SPN_1XX: SDK error
    SPN_2XX: API error
    SPN_3XX: Player error

  error_type:
    SDK: SDK error
    API: API error
    PLAYER: Player error
 */

const ERRORS = {};

ERRORS.SPN101 = {
  success: false,
  errorCode: "SPN_101",
  errorType: "SDK_Error",
  errorMessage: ""
};

ERRORS.SPN102 = {
  success: false,
  errorCode: "SPN_102",
  errorType: "SDK_Error",
  errorMessage: ""
};

ERRORS.SPN103 = {
  success: false,
  errorCode: "SPN_103",
  errorType: "SDK_Error",
  errorMessage: ""
};

ERRORS.SPN104 = {
  success: false,
  errorCode: "SPN_103",
  errorType: "SDK_Error",
  errorMessage: ""
};

ERRORS.SPN201 = {
  success: false,
  errorCode: "SPN_201",
  errorType: "API_Error",
  errorMessage: ""
};

ERRORS.SPN202 = {
  success: false,
  errorCode: "SPN_202",
  errorType: "API_Error",
  errorMessage: ""
};

ERRORS.SPN251 = {
  success: false,
  errorCode: "SPN_251",
  errorType: "API_Error",
  errorMessage: ""
};

ERRORS.SPN252 = {
  success: false,
  errorCode: "SPN_252",
  errorType: "API_Error",
  errorMessage: ""
};

export default ERRORS;
