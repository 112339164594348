/* eslint-disable quotes */
const API_ENDPOINTS_BASE = `{{cluster}}/{{language}}/{{platform}}/{{country}}/`;

export const API_MIDDLEWARE = `AGL`;

export const API_BASE_URL = {
  PROD: "https://apiv2.sonyliv.com",
  PREPROD: "https://apipreprod.sonyliv.com",
  STAGING: "https://apistg.sonyliv.com",
  QA: "https://apiqa.sonyliv.com"
};

export const API_VERSIONS = {
  BRANDING: "1.4",
  BUNDLE: "2.6",
  DETAIL: "2.1",
  DICTIONARY: "1.4",
  GETPROFILE: "1.6",
  GETXDR: "1.5",
  INITIALCONFIG: "1.5",
  PARTNERLOGIN: "1.9",
  PARTNERVALIDATE: "1.0",
  ULD: "1.4",
  VIDEOURL: "1.5"
};

export const API_ENDPOINTS = {
  BRANDING: `${API_ENDPOINTS_BASE}USER/INITIAL/BRANDING`,
  BUNDLE: `${API_ENDPOINTS_BASE}{{stateCode}}/CONTENT/DETAIL/BUNDLE/{{assetId}}`,
  DETAIL: `${API_ENDPOINTS_BASE}{{stateCode}}/DETAIL/{{assetId}}`,
  DICTIONARY: `${API_ENDPOINTS_BASE}CONFIG/DICTIONARY`,
  GETPROFILE: `${API_ENDPOINTS_BASE}GETPROFILE`,
  GETXDR: `${API_ENDPOINTS_BASE}USER/XDR`,
  INITIALCONFIG: `${API_ENDPOINTS_BASE}INITIAL/CONFIG`,
  PARTNERLOGIN: `${API_ENDPOINTS_BASE}{{stateCode}}/USER/PARTNERLOGIN`,
  PARTNERVALIDATE: `PARTNER/VALIDATE`,
  ULD: `${API_ENDPOINTS_BASE}USER/ULD`,
  VIDEOURL: `${API_ENDPOINTS_BASE}CONTENT/VIDEOURL/VOD/{{assetId}}`
};
