import { API_BASE_URL, API_MIDDLEWARE } from "../constants/api.constants";
import {
  getConfigData,
  getProfile,
  getULD,
  partnerLogin,
  partnerValidate
} from "../constants/api.service";
import ERRORS from "../constants/error.constants";
import {
  EVENT_CALLBACK,
  HANDSHAKE_EVENTS,
  SDK_EVENTS,
  SDK_STATUS
} from "../constants/events.constants";
import { PLAYER_DIV, PLAYER_IFRAME } from "../constants/ui.constants";
import Handshake from "../utils/Handshake";
import {
  extractBasePath,
  _console
  //  isPrivateMode
} from "../utils/player.utils";
import {
  validateInitSdkConfig,
  validatePlayContentConfig
} from "../utils/sdk.validator";
// import Postmate from "../Postmate";
import PlayerCallbacks from "./PlayerCallbacks";
import VideoLoader from "./ui/loader";
import SplashScreen from "./ui/splashScreen";

/**
 * @brief SPNSDKManager extents PlayerCallbacks
 * @export
 * @class SPNSDKManager
 */
export default class SPNSDKManager extends PlayerCallbacks {
  handshake;

  handshakeChild;

  IFRAME_LOAD_STATUS = SDK_STATUS.NOT_STARTED;

  /**
   * @param {*} options
   * @param {number} playerIndex
   */
  constructor(options, sdkManager) {
    validateInitSdkConfig(options);
    super();
    this.playerIndex = sdkManager.playerIndex;
    this.broadcastSdkEvent = sdkManager.broadcastSdkEvent;
    this.isPlayerCreated = false;
    this.eventCallbacks = {};
    this.elementRef = {};
    this.sdkConfig = options;
    this.sdkConfig.loadPlayerInIframe = options.useIFrame;
    // typeof window.USE_IFRAME === "undefined" ? true : window.USE_IFRAME;
    this.basePath = extractBasePath([
      "spn-partner-sdk.js",
      "spn-partner-sdk-debug.js"
    ]);

    if (!this.sdkConfig.loadPlayerInIframe) {
      if (document.readyState === "complete") {
        this.createLocalPlayer();
      } else {
        window.addEventListener("load", () => {
          this.createLocalPlayer();
        });
      }
    }

    this.splashScreen = new SplashScreen(options.brandingData);
    this.videoLoader = new VideoLoader();
    this.videoLoader.render();
    this.timer = {};

    // this.registerResizeEventListener();
    // this.splashScreen.render({
    //   parent: document.getElementById("playerWrapper")
    // });
  }

  /* eslint-disable */
  get version() {
    return {
      buildType: PLAYER_BUILD,
      sdkVersion: SDK_VERSION,
      commitHash: COMMITHASH,
      buildDate: BUILD_DATE,
      branch: BRANCH
    };
  }
  /* eslint-enable */

  /**
   * @param {object} sdkConfig
   * @param {method} callback
   */
  prefetchPlayerSdk = (sdkConfig, callback) => {
    /*
      Create iframe and register iframe load callback
      but do not create actual player and set a variable
      in namespace that iframe is loaded
     */
    this.broadcastSdkEvent(SDK_EVENTS.SDK_PREFETCH_START);
    _console.log("prefetchPlayerSdk");
    if (this.IFRAME_LOAD_STATUS === SDK_STATUS.NOT_STARTED) {
      this.sdkConfig = {
        ...this.sdkConfig,
        ...sdkConfig
      };
      this.IFRAME_LOAD_STATUS = SDK_STATUS.IN_PROGRESS;
      this.shouldPlayContentOnLoad = false;
      this.createPlayerIframe();
      callback({
        success: true,
        message: "prefetchPlayerSdk"
      });
    } else {
      callback({
        success: false,
        message: "Player already loaded"
      });
    }
    this.broadcastSdkEvent(SDK_EVENTS.SDK_PREFETCH_END);
  };

  /**
   * @brief initial api calls
   * @param {object} options
   * @param {function} callback - Callback function to be called on receiving api response
   */
  static async init(options, callback) {
    // const isPrivate = await isPrivateMode();
    // alert("isPrivateMode: " + isPrivate);
    const {
      uniqueId,
      deviceToken,
      deviceType,
      shortToken,
      partnerToken,
      useIFrame
    } = options;

    const config = {
      useIFrame,
      xhrOptions: {
        appVersion: "1.0.0",
        sessionId: "c7fd64951a00410fae211779f1edcfbb-1646035047937",
        uniqueId,
        deviceToken,
        deviceType,
        shortToken,
        partnerToken,
        deviceId: options.deviceToken
      },
      apiOptions: {
        baseUrl: API_BASE_URL.PROD,
        middleware: API_MIDDLEWARE,
        platform: "TATA_SKY_BINGE_SDK_ANDROID",
        // platform: "TATA_SKY_BINGE_SDK_WEB",
        // platform: "WEB",
        language: "ENG",
        version: null,
        country: "ALL",
        state: "",
        cluster: "A"
      }
    };

    try {
      const partnerValidateData = await partnerValidate(config);
      config.xhrOptions.securityToken = partnerValidateData.resultObj.token;
      // config.xhrOptions.securityToken = window.LOGIN_TOKEN.security_token;

      const uldData = await getULD(config);
      config.uldData = uldData;
      config.apiOptions.country = uldData.resultObj.country_code;
      config.apiOptions.state = uldData.resultObj.state_code;

      const partnerLoginData = await partnerLogin(config);
      config.xhrOptions.authorization = partnerLoginData.resultObj.accessToken;
      // config.xhrOptions.authorization = window.LOGIN_TOKEN.authorization;
      config.customerID = partnerLoginData.resultObj.partnerCustomerID;
      config.cpCustomerID = partnerLoginData.resultObj.cpCustomerID;
      // config.xhrOptions.deviceId = `${
      //   config.xhrOptions.deviceId
      // }-${new Date().getTime()}`;

      const profileData = await getProfile(config);
      const packageId =
        profileData.resultObj.contactMessage[0].subscription
          .accountServiceMessage[0]?.serviceID || null;
      config.profileData = profileData;
      config.apiOptions.cluster =
        profileData.resultObj.contactMessage[0].userStateParam || "A";

      const configData = await getConfigData({
        ...config,
        packageId
      });
      config.configData = configData;
      config.brandingData = configData.resultObj?.config.dynamic_splash_asset;
      config.waterMark = configData.resultObj?.config.watermark;

      callback({
        success: true,
        message: "SDK initialized",
        result: config
      });
    } catch (error) {
      // _console.error("initSDK error", error);
      const errorObj = ERRORS.SPN201;
      errorObj.errorMessage = error.message || error.title;
      // this.sdkCallback(SDK_EVENTS.EMIT_ERROR, errorObj);
      callback(errorObj);
    }
  }

  /**
   * @returns {string} - baseURL
   */
  getIframeUrl() {
    let debugMode = "";
    // eslint-disable-next-line no-undef
    if (DEBUG_MODE) {
      debugMode = "-debug";
    }
    const baseUrl = `${this.basePath}/iframe/player${debugMode}.html`;
    // const baseUrl = `https://static-assets.sonyliv.com/filesystem/platform/web/tatasky/03-10/iframe/player${debugMode}.html`;
    return baseUrl;
  }

  /**
   * @param {object} sdkConfig
   * @param {domNode} sdkConfig.playerDiv - parent div
   * @param {object} sdkConfig.video - video id
   * @param {object} sdkConfig.playerConfig - parent
   * @param {function} sdkConfig.playerCallback
   * @param {function} sdkConfig.playerCustomCallback
   * @param {function} sdkConfig.adCallback
   * @param {function} sdkConfig.sdkReadyCallback
   */
  playContent = (sdkConfig, callback = () => {}) => {
    this.broadcastSdkEvent(SDK_EVENTS.PLAY_CONTENT_START);

    this.playContentCallback = callback;

    this.sdkConfig = {
      ...this.sdkConfig,
      ...sdkConfig
    };

    try {
      validatePlayContentConfig(this.sdkConfig);
    } catch (error) {
      const errorObj = ERRORS.SPN103;
      errorObj.errorMessage = error.message;
      this.sdkCallback(SDK_EVENTS.EMIT_ERROR, errorObj);
      this.broadcastSdkEvent(SDK_EVENTS.PLAY_CONTENT_FAILED, errorObj);
      callback(errorObj);
      return;
    }

    /*
      TODO:
      1.Validate Config
      2. Start timeout for 25seconds if player is not loaded throw error
      3. If Iframe is not loaded.
      4. Iframe timeout
      5. Inside Iframe Errors
    */

    if (!document.getElementById(PLAYER_IFRAME.ID)) {
      this.isPlayerCreated = false;
      this.IFRAME_LOAD_STATUS = SDK_STATUS.NOT_STARTED;
    }

    if (this.sdkConfig.brandingData.only_first_time) {
      const splashRendered = sessionStorage.getItem("splashRendered");
      if (!splashRendered) {
        this.splashScreen.render({
          parent: this.sdkConfig.playerDiv
        });
        sessionStorage.setItem("splashRendered", 1);
      } else {
        this.videoLoader.showVideoLoader();
      }
    } else {
      this.videoLoader.showVideoLoader();
    }

    this.PLAY_CONTENT_ON_LOAD = true;

    if (this.isPlayerCreated) {
      this.changeContent(this.sdkConfig);
      return;
    }

    if (this.sdkConfig.loadPlayerInIframe) {
      switch (this.IFRAME_LOAD_STATUS) {
        case SDK_STATUS.NOT_STARTED:
          this.createPlayerIframe();
          break;
        case SDK_STATUS.IN_PROGRESS:
          break;
        case SDK_STATUS.COMPLETED:
          this.createPlayerInsideIframe(this.handshakeChild);
          break;
      }
    } else {
      this.createIframeAndEnableHandshake({
        loadPlayerInIframe: this.sdkConfig.loadPlayerInIframe
      });
    }
  };

  /**
   * @brief -calls createPlayerInsideIframe
   */
  changeContent = () => {
    this.broadcastSdkEvent(SDK_EVENTS.CHANGE_CONTENT_START);
    this.sdkConfig.contentChanged = true;
    this.createPlayerInsideIframe(this.handshakeChild);
    // this.broadcastSdkEvent(SDK_EVENTS.CHANGE_CONTENT_END);
    // this.videoLoader.showVideoLoader();
  };

  createLocalPlayer = () => {
    const mainVideo = document.createElement("div");
    mainVideo.setAttribute("id", "main_video");
    mainVideo.setAttribute(
      "style",
      "width: 100%; height: 100%; position: absolute"
    );
    const div1 = document.createElement("div");
    div1.setAttribute("style", "width: 100%; height: 100%;");
    const div2 = document.createElement("div");
    div2.setAttribute("style", "width: 100%; height: 100%;");
    const div3 = document.createElement("div");
    div3.setAttribute("style", "width: 100%; height: 100%;");
    const div4 = document.createElement("div");
    div4.setAttribute("style", "width: 100%; height: 100%;");
    const div5 = document.createElement("div");
    div5.setAttribute("style", "width: 100%; height: 100%;");
    div5.appendChild(mainVideo);
    div4.appendChild(div5);
    div3.appendChild(div4);
    div2.appendChild(div3);
    div1.appendChild(div2);

    this.elementRef[PLAYER_DIV.REF] = document.getElementById("playerWrapper");
    this.elementRef[PLAYER_DIV.REF].appendChild(div1);

    let debugMode = "";
    // eslint-disable-next-line no-undef
    if (DEBUG_MODE) {
      debugMode = "-debug";
    }

    const clientSdk = document.createElement("script");
    clientSdk.src = `${this.basePath}/iframe/spn-client-sdk${debugMode}.js`;
    clientSdk.onload = () => {
      window.IFRAME_MANAGER.loadSPNPlayerSDK();
    };
    // const playerSdk = document.createElement("script");
    // playerSdk.src = "./iframe/player/playersdk.js";

    window.PLAYER_DEBUG_MODE = false;
    window.CURRENT_PLAYER_ENV = "WEB_HEADLESS";
    window.ULD_RESPONSE_RECEIVED = true;
    window.USE_HARDCODE_PLAYER = "V2";

    document.body.appendChild(clientSdk);
    // document.body.appendChild(playerSdk);
  };

  /**
   * @brief -create and setIframe config
   */
  createPlayerIframe = () => {
    this.elementRef[PLAYER_DIV.REF] = this.sdkConfig.playerDiv;
    if (this.sdkConfig.loadPlayerInIframe) {
      this.elementRef[PLAYER_DIV.REF].innerHTML = "";
    }
    const url = this.getIframeUrl();
    const config = {
      loadPlayerInIframe: this.sdkConfig.loadPlayerInIframe,
      iframeConfig: {
        url,
        id: PLAYER_IFRAME.ID,
        name: PLAYER_IFRAME.NAME,
        classListArray: [PLAYER_IFRAME.CLASS],
        container: this.elementRef[PLAYER_DIV.REF],
        attributes: {
          width: "1280px",
          height: "720px",
          frameborder: "0",
          scrolling: "no",
          allowtransparency: "true",
          allow:
            "autoplay *; fullscreen *; encrypted-media *; allow-same-origin *;",
          style:
            "position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);width:100%;height:100%;"
        }
      }
    };
    this.createIframeAndEnableHandshake(config);
  };

  /**
   * @param {object} config
   * @param {boolean} config.loadPlayerInIframe
   * @param {object} config.iframeConfig
   */
  createIframeAndEnableHandshake = (config) => {
    this.broadcastSdkEvent(SDK_EVENTS.IFRAME_LOAD_START);
    this.handshake = new Handshake(config);
    this.handshake
      .then((child) => {
        this.broadcastSdkEvent(SDK_EVENTS.IFRAME_LOAD_COMPLETE);
        this.handshakeChild = child;
        this.elementRef[PLAYER_IFRAME.REF] = child.frame;
        this.IFRAME_LOAD_STATUS = SDK_STATUS.COMPLETED;
        // this.scaleIframe();
        if (this.PLAY_CONTENT_ON_LOAD) {
          this.createPlayerInsideIframe(child);
        } else {
          this.iframeOnLoad(child);
        }
      })
      .catch((error) => {
        this.broadcastSdkEvent(SDK_EVENTS.IFRAME_LOAD_FAILED);
        _console.log(error);
        // this.sdkConfig.loadPlayerInIframe = false;
        // this.createLocalPlayer();
      });
  };

  /**
   * @brief -handles a boolean when iFrame is loaded
   */
  iframeOnLoad = () => {
    if (this.PLAY_CONTENT_ON_LOAD) {
      this.PLAY_CONTENT_ON_LOAD = false;
      // this.createPlayerInsideIframe(child);
    }
  };

  /**
   * @brief - subscribe to events
   * @param {iframe} child - iframe
   */
  registerIframeEvents = (child) => {
    child.resetEvents();
    child.on(EVENT_CALLBACK.SDK_EVENTS, this.sdkCallback);
    child.on(EVENT_CALLBACK.NATIVE_EVENTS, this.playerCallback);
    child.on(EVENT_CALLBACK.CUSTOM_EVENTS, this.playerCustomCallback);
    child.on(EVENT_CALLBACK.AD_EVENTS, this.adCallback);
  };

  /**
   * @brief emit initializeSDK and content change event
   * @param {iframe} child - iframe
   */
  createPlayerInsideIframe = (child) => {
    this.registerIframeEvents(child);
    const assetId = this.sdkConfig.video.videoId;
    const {
      apiOptions,
      contentChanged,
      loadPlayerInIframe,
      // playerConfig,
      profileData,
      configData,
      uldData,
      xhrOptions,
      waterMark
    } = this.sdkConfig;

    const playerConfig = this.getPlayerConfig();

    const sdkConfig = {
      apiOptions,
      assetId,
      loadPlayerInIframe,
      playerConfig,
      profileData,
      configData,
      uldData,
      xhrOptions,
      contentChanged,
      waterMark
    };

    if (!loadPlayerInIframe) {
      sdkConfig.handshake = this.handshakeChild;
    }

    if (contentChanged) {
      this.sdkConfig.contentChanged = false;
      this.broadcastSdkEvent(SDK_EVENTS.CHANGE_CONTENT_CALL);
      child.call(HANDSHAKE_EVENTS.CHANGE_CONTENT, sdkConfig);
    } else {
      this.broadcastSdkEvent(SDK_EVENTS.INITIALIZE_PLAYER_MANAGER);
      child.call(HANDSHAKE_EVENTS.INITIALIZE_PLAYER_MANAGER, sdkConfig);
    }
    // this.playContentCallback(null, true);
    // this.playContentCallback = null;
  };

  /**
   * @returns {object} -playerConfig
   */
  getPlayerConfig = () => {
    let { playerConfig } = this.sdkConfig;
    if (!playerConfig) {
      playerConfig = {};
    }
    function checkKeyandSetDefault(key, defaultValue) {
      if (key in playerConfig) {
        if (playerConfig[key] === true) {
          playerConfig[key] = true;
        } else if (playerConfig[key] === false) {
          playerConfig[key] = false;
        } else {
          playerConfig[key] = defaultValue;
        }
      } else {
        playerConfig[key] = defaultValue;
      }
    }

    checkKeyandSetDefault("enableUI", false);
    checkKeyandSetDefault("useIFrame", true);
    checkKeyandSetDefault("enableAds", false);
    checkKeyandSetDefault("adServerUrl", null);

    return playerConfig;
  };

  scaleIframe = () => {
    // _console.log("scaleIframe");
    const tvIframeDiv = this.elementRef[PLAYER_DIV.REF].parentElement;
    const tvIframeParent = this.elementRef[PLAYER_DIV.REF];
    const tvIframe = this.elementRef[PLAYER_IFRAME.REF];
    if (!tvIframeDiv) {
      return;
    }
    if (!tvIframeParent) {
      return;
    }
    if (!tvIframe) {
      return;
    }
    const tvIframeHeight = tvIframe.offsetHeight;
    const tvIframeWidth = tvIframe.offsetWidth;
    const tvIframeDivWidth = tvIframeDiv.offsetWidth;
    const tvIframeDivHeight = tvIframeDiv.offsetHeight;
    const tvRatio = 16 / 9;
    const tvIframeDivRatio = tvIframeDivWidth / tvIframeDivHeight;
    let finalWidth;
    let finalHeight;
    if (tvIframeDivRatio < tvRatio) {
      finalWidth = tvIframeDivWidth;
      finalHeight = tvIframeDivWidth / tvRatio;
    } else {
      finalHeight = tvIframeDivHeight;
      finalWidth = tvIframeDivHeight * tvRatio;
    }
    tvIframeParent.style.width = `${finalWidth}px`;
    tvIframeParent.style.height = `${finalHeight}px`;
    const scaleX = finalWidth / tvIframeWidth;
    const scaleY = finalHeight / tvIframeHeight;
    let transformString = `scale(${scaleX},${scaleY})`;
    const oldTransform = "translate(-50%,-50%)";
    // tvIframe.getComputedStyle().getPropertyValue("transform");
    transformString = `${oldTransform} ${transformString}`;
    tvIframe.style.transform = transformString;
  };

  onWindowResize = () => {
    this.scaleIframe();
  };

  registerResizeEventListener = () => {
    window.addEventListener("resize", this.onWindowResize);
  };

  /**
   * @brief Callback for player SDK events
   * @param {*} { eventName, eventData }
   * @memberof SPNSDKManager
   */
  sdkCallback = ({ eventName, eventData }) => {
    this.broadcastSdkEvent(eventName, eventData);
    switch (eventName) {
      case SDK_EVENTS.SDK_READY:
        if (!this.isPlayerCreated) {
          this.isPlayerCreated = true;
          if (typeof this.sdkConfig.sdkReadyCallback === "function") {
            this.sdkConfig.sdkReadyCallback(eventData);
          }
        }
        break;
      case SDK_EVENTS.EMIT_ERROR:
        this.emit("error", eventData);
        break;
    }
  };

  on = (event, callback) => {
    this.eventCallbacks[event] = callback;
  };

  emit = (event, data) => {
    if (this.eventCallbacks[event]) {
      this.eventCallbacks[event](data);
    }
  };
}
