const compareObjectKeyTypes = (expectedOptions, options) => {
  const expectedOptionsKeys = Object.keys(expectedOptions);
  // const optionsKeys = Object.keys(options);
  const expectedOptionsKeysLength = expectedOptionsKeys.length;
  // const optionsKeysLength = optionsKeys.length;
  // if (optionsKeysLength !== expectedOptionsKeysLength) {
  // throw new Error(
  //  `Invalid options. Expected ${expectedOptionsKeysLength} options, but got ${optionsKeysLength}`
  // );
  // }
  for (let i = 0; i < expectedOptionsKeysLength; i += 1) {
    const optionKey = expectedOptionsKeys[i];
    const optionValue = expectedOptions[optionKey];
    const option = options[optionKey];
    // eslint-disable-next-line valid-typeof
    if (option === null || typeof option !== optionValue) {
      throw new Error(
        `Invalid option ${optionKey}. Expected ${optionValue}, but got ${
          option === null ? null : typeof option
        }`
      );
    }
  }
};

export const validateInitSDKParameters = (options) => {
  if (!options || typeof options !== "object") {
    throw new Error(
      `Invalid config. Expected object, but got ${typeof options}`
    );
  }
  const expectedOptions = {
    createPlayer: "boolean",
    useIFrame: "boolean",
    shortToken: "string",
    uniqueId: "string",
    deviceType: "string",
    deviceToken: "string",
    partnerToken: "string"
  };
  compareObjectKeyTypes(expectedOptions, options);
};

export const validateInitSdkConfig = (options) => {
  if (!options || typeof options !== "object") {
    throw new Error(
      `Invalid config. Expected object, but got ${typeof options}`
    );
  }
  const expectedOptions = {
    apiOptions: "object",
    brandingData: "object",
    cpCustomerID: "string",
    customerID: "string",
    // loadPlayerInIframe: "boolean",
    // playerDiv: "object",
    profileData: "object",
    uldData: "object",
    waterMark: "object",
    xhrOptions: "object"
  };
  compareObjectKeyTypes(expectedOptions, options);
};

export const validatePlayContentConfig = (options) => {
  if (!options || typeof options !== "object") {
    throw new Error(
      `Invalid config. Expected object, but got ${typeof options}`
    );
  }
  const expectedOptions = {
    apiOptions: "object",
    brandingData: "object",
    cpCustomerID: "string",
    customerID: "string",
    loadPlayerInIframe: "boolean",
    playerDiv: "object",
    profileData: "object",
    uldData: "object",
    waterMark: "object",
    xhrOptions: "object"
  };
  compareObjectKeyTypes(expectedOptions, options);
};
