/*
  This file contains the element ref/classes/id constants
*/

import _VIDEO_LOADER_ICON from "../SPN_PARTNER_SDK/assets/img/sample.gif";

export const PLAYER_DIV = {
  REF: "playerDiv",
  CLASS: "playerDiv",
  ID: "playerDiv"
};

export const PLAYER_IFRAME = {
  REF: "spnPlayerIframe",
  NAME: "spnPlayerIframe",
  CLASS: "spnPlayerIframe",
  ID: "spnPlayerIframe"
};

export const VIDEO_LOADER_ELE = "videoLoader";
export const VIDEO_LOADER_ICON = _VIDEO_LOADER_ICON;
export const SPLASH_CONTAINER = {
  REF: "splashContainer",
  NAME: "splashContainer",
  CLASS: "splashContainer",
  ID: "splashContainer"
};

export const WATER_MARK_CONTAINER = {
  REF: "waterMarkContainer",
  CLASS: "waterMarkContainer",
  ID: "waterMarkContainer"
};
