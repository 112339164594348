import Postmate from "./Postmate";

export default class Handshake {
  constructor(options) {
    this.config = options;
    this.loadPlayerInIframe = options.loadPlayerInIframe;
    this.iframeConfig = options.iframeConfig;
    this.subscribers = [];
    this.checkCount = 0;
    this.maxCheckCount = 5;

    // eslint-disable-next-line no-constructor-return
    return this.initialize();
  }

  initialize() {
    if (this.loadPlayerInIframe) {
      // Postmate.debug = true;
      return new Postmate(this.iframeConfig);
    }
    return new Promise((resolve, reject) => {
      const checkIframeLoaded = () => {
        if (window.IFRAME_MANAGER) {
          this.iframeManager = window.IFRAME_MANAGER;
          resolve(this);
        } else if (this.checkCount < this.maxCheckCount) {
          this.checkCount++;
          setTimeout(checkIframeLoaded, 500);
        } else {
          reject();
        }
      };
      checkIframeLoaded();
    });
  }

  get = (val) => Promise.resolve(this.iframeManager[val]);

  resetEvents = () => {
    this.subscribers = [];
  };

  on = (event, callback) => {
    this.subscribers.push({
      event,
      callback
    });
  };

  emit = (eventName, eventData) => {
    this.subscribers.forEach(({ event, callback }) => {
      if (event === eventName) {
        callback(eventData);
      }
    });
  };

  call = (method, params, sendResponse) => {
    if (sendResponse) {
      return Promise.resolve(this.iframeManager[method](params));
    }
    return this.iframeManager[method](params);
  };
}
