import { API_ENDPOINTS, API_VERSIONS } from "../constants/api.constants";

/* eslint-disable no-console */
// eslint-disable-next-line no-underscore-dangle
export const _console = (() => {
  const consoleRef = {};
  const methods = ["log", "warn", "error", "info", "debug", "trace"];
  methods.forEach((method) => {
    // eslint-disable-next-line no-undef
    if (DEBUG_MODE) {
      consoleRef[method] = console[method].bind(console);
    } else {
      consoleRef[method] = () => {};
    }
    if (!["error"].includes(method)) {
      console[method] = () => {};
    }
  });
  return consoleRef;
})();
/* eslint-enable no-console */

/**
 * @brief Get API URL
 * @export getApiUrl
 * @param {object} options - URL options
 * @param {string} [options.platform] - platform
 * @param {string} [options.language] - language
 * @param {string} [options.baseUrl] - base url
 * @param {string} [options.middleware] - middleware
 * @param {string} options.endpoint - API endpoint
 * @param {string} [options.version] - API version
 * @param {string} [options.country] - country code
 * @param {string} [options.state] - state code
 * @param {string} [options.cluster] - cluster code
 * @param {string} [options.assetId] - asset id
 * @return {string} - api url
 */
export function getApiUrl(options) {
  // const platform = "WEB" || "TATA_SKY_BINGE_SDK_WEB";
  const {
    platform,
    language,
    baseUrl,
    middleware,
    endpoint,
    version,
    country,
    state,
    cluster,
    assetId
  } = options;

  let url = `${baseUrl}/${middleware}/`;
  if (version) {
    url += `${version}/`;
  } else {
    url += `${API_VERSIONS[endpoint]}/`;
  }
  url += `${API_ENDPOINTS[endpoint]}`;
  url = url.replace("{{cluster}}", cluster);
  url = url.replace("{{language}}", language);
  url = url.replace("{{platform}}", platform);
  url = url.replace("{{country}}", country);
  url = url.replace("{{stateCode}}", state);
  url = url.replace("{{assetId}}", assetId);
  return url;
}

/**
 * @brief Create XHR request
 * @export
 * @param {string} url - url
 * @param {object} options - XHR options
 * @param {string} options.method - request method
 * @param {object} options.headers - request headers
 * @param {object} options.body - request body
 * @return {promise} - XHR promise
 */
export function xhr(url, options) {
  const requestMethod = options.method || "GET";
  const requestHeaders = options.headers || {};
  const requestBody = options.body || {};
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    const handleSuccessEvent = (event) => {
      try {
        const response = JSON.parse(request.response);
        if (request.status === 200) {
          // _console.log("resolved", url, event.type);
          resolve(response);
        } else {
          _console.log("rejected", url, event.type);
          reject(response);
        }
      } catch (error) {
        reject(error);
      }
    };

    const handleErrorEvent = (event) => {
      _console.log("rejected", url, event.type);
      reject(event);
    };

    request.withCredentials = true;
    request.addEventListener("loadend", handleSuccessEvent);
    request.addEventListener("error", handleErrorEvent);
    request.addEventListener("abort", handleErrorEvent);

    request.open(requestMethod, url);
    if (typeof requestHeaders === "object") {
      Object.keys(requestHeaders).forEach((key) => {
        if (requestHeaders[key]) {
          request.setRequestHeader(key, requestHeaders[key]);
        }
      });
    }
    if (requestBody) {
      try {
        request.send(JSON.stringify(requestBody));
      } catch (error) {
        _console.error("Error in sending request", error);
      }
    } else {
      request.send();
    }
  });
}

/**
 * @brief Get query params from url
 * @export getQueryParams
 * @param {string} url
 * @return {object}
 */
export const getQueryParams = (url) => {
  const queryString = url.split("?")[1];
  const queryParams = {};
  if (queryString) {
    queryString.split("&").forEach((part) => {
      const item = part.split("=");
      queryParams[item[0]] = decodeURIComponent(item[1]);
    });
  }
  return queryParams;
};

export function filterVal(inp, defVal) {
  if (typeof inp === "undefined") return defVal;
  if (inp === "") return defVal;
  return inp;
}

export function filterBoolVal(inp, defVal) {
  if (filterVal(inp, defVal) === defVal) return defVal;
  return filterVal(inp, defVal) === "true" || filterVal(inp, defVal) === "1";
}

export function filterURL(inp, defVal) {
  return decodeURIComponent(filterVal(inp, defVal));
}

/**
 * @brief UI of time remaining
 * @export getCurTime
 * @return {string} h:mm:ss:ms
 */
export function getCurTime() {
  const today = new Date();
  const h = today.getHours();
  let m = today.getMinutes();
  let s = today.getSeconds();
  const ms = today.getMilliseconds();

  const checkTime = (i) => {
    let time = i;
    if (time < 10) {
      time = `0${time}`;
    }
    return time;
  };

  m = checkTime(m);
  s = checkTime(s);
  return `${h}:${m}:${s}:${ms}`;
}

export function extractBasePath(scriptList) {
  const searchString = scriptList
    .map((script) => `script[src*="${script}"]`)
    .join(", ");

  const script = document.querySelector(searchString);
  if (script) {
    return script.src.split("/").slice(0, -1).join("/");
  }
  return "";
}

export function createDOMElement(dom) {
  const { innerHTML, attributes, ele, styles, event, innerText, parent } = dom;
  const element = document.createElement(ele);
  if (attributes) {
    Object.keys(attributes).forEach((key) => {
      element.setAttribute(key, attributes[key]);
    });
  }
  if (styles) {
    Object.keys(styles).forEach((key) => {
      element.style[key] = styles[key];
    });
  }
  if (innerHTML) {
    element.innerHTML = innerHTML;
  }
  if (innerText) {
    element.innerHTML = innerText;
  }
  if (event && !event.isDisabled) {
    element.addEventListener(event.type, event.listener, event.capturing);
  }
  if (parent) {
    parent.appendChild(element);
  }
  return element;
}
