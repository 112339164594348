import ERRORS from "../constants/error.constants";
import {
  ADS_EVENTS,
  CUSTOM_EVENTS,
  NATIVE_EVENTS,
  SDK_EVENTS
} from "../constants/events.constants";
import EventManager from "../utils/eventManager";
import PerformanceService from "../utils/performance.service";
import { _console } from "../utils/player.utils";
import { validateInitSDKParameters } from "../utils/sdk.validator";
import SPNSDKManager from "./SPNSDKManager";

/**
 * @brief SpnManager
 * @export
 * @class SpnManager
 */
class SpnManager {
  constructor() {
    this.eventManager = new EventManager();
    this.performanceObj = new PerformanceService({
      eventManager: this.eventManager
    });
    this.playerIndex = 0;
    this.PLAYERLIST = {};
    this.EVENTS = {
      NATIVE_EVENTS,
      CUSTOM_EVENTS,
      ADS_EVENTS
    };
    this.broadcastSdkEvent = this.eventManager.broadcastSdkEvent;
    this.broadcastSdkEvent(SDK_EVENTS.SPN_MANAGER_INITIALIZED);
  }

  get performance() {
    return this.performanceObj?.performance || null;
  }

  /**
   * @brief Initialize the SDK
   * @param {object} options
   * @param {boolean} options.createPlayer
   * @param {string} options.shortToken
   * @param {string} options.uniqueId
   * @param {string} options.deviceType
   * @param {string} options.deviceToken
   * @param {string} options.partnerToken
   * @param {function} callback - call the callback on receiving response from SPNSDKManager.init()
   */
  initSDK = (options, callback = () => {}) => {
    this.eventManager.broadcastSdkEvent(SDK_EVENTS.SDK_INIT_START);
    const config = options;
    if (!("createPlayer" in config)) {
      config.createPlayer = true;
    }

    if (!("useIFrame" in config)) {
      config.useIFrame = true;
      // config.useIFrame = false;
    }

    try {
      validateInitSDKParameters(config);
    } catch (error) {
      this.eventManager.broadcastSdkEvent(SDK_EVENTS.SDK_INIT_FAILED);
      const errorObj = ERRORS.SPN101;
      errorObj.errorMessage = error.message;
      callback(errorObj);
      return;
    }

    const onSDKInitialized = (res) => {
      _console.log("onSDKInitialized", res.success);
      let callbackData = {};
      if (res.success) {
        this.INIT_RESPONSE = res;
        callbackData.success = true;
        callbackData.result = res;
        callbackData.message = "SPN SDK Initialized";
        if (config.createPlayer === true) {
          try {
            callbackData.player = this.createPlayer();
          } catch (error) {
            const errorObj = ERRORS.SPN102;
            errorObj.errorMessage = error.message;
            this.eventManager.broadcastSdkEvent(SDK_EVENTS.SDK_INIT_FAILED);
            callback(errorObj);
            return;
          }
        }
      } else {
        callbackData = {
          ...res
        };
        // callbackData.success = false;
        callbackData.message = "SPN SDK not Initialized";
      }
      this.eventManager.broadcastSdkEvent(SDK_EVENTS.SDK_INIT_END);
      callback(callbackData);
    };

    SPNSDKManager.init(config, onSDKInitialized);
  };

  /**
   * @brief instantiate SPNSDKManager
   * @returns {object} - object of SPNSDKManager if SPN SDK Initialized
   */
  createPlayer = () => {
    if (this.INIT_RESPONSE?.success) {
      const spnSdkManager = new SPNSDKManager(this.INIT_RESPONSE.result, this);
      this.spnSdkManager = spnSdkManager;
      this.PLAYERLIST[this.playerIndex] = spnSdkManager;
      this.playerIndex += 1;
      return spnSdkManager;
    }
    return null;
  };
}

window.SPN_MANAGER = new SpnManager();
