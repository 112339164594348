import { EVENT_CALLBACK } from "../constants/events.constants";

export default class PerformanceService {
  constructor({ eventManager }) {
    this.eventManager = eventManager;
    this.baseTime = performance.now();
    this._performance = [];
    this.subscribeToEvents();
  }

  get performance() {
    return this._performance;
  }

  /**
   * @param {string} eventName
   * @returns {void}
   * @memberof PerformanceService
   * @description
   * Add a new performance entry to the performance array
   * @example
   * performanceService.addPerformanceEntry('test');
   * performanceService.getPerformance();
   * // [{name: 'test', time: 100}]
   */
  log(eventName) {
    const time = parseFloat((performance.now() - this.baseTime).toFixed(2));
    const eventData = [eventName, time];
    this._performance = this._performance.filter((e) => e[0] !== eventName);
    this._performance.push(eventData);
  }

  subscribeToEvents() {
    this.eventManager.subscribeToEvents({
      eventType: EVENT_CALLBACK.SDK_EVENTS,
      eventCallback: this.sdkCallback.bind(this),
      senderName: "PerformanceService"
    });
  }

  sdkCallback(eventName) {
    this.log(eventName);
  }
}
