import { createDOMElement } from "../../../utils/player.utils";
// import "./styles.scss";
import {
  VIDEO_LOADER_ELE,
  VIDEO_LOADER_ICON
} from "../../../constants/ui.constants";

export default class VideoLoader {
  constructor() {
    // this.currentUI = currentUI;
    this.elementRef = {};
  }

  render() {
    const mainUIWrapper = document.getElementsByClassName("player-wrapper")[0];
    const element = this.createLoader();
    mainUIWrapper.appendChild(element);
    this.hideVideoLoader();
    // this.showVideoLoader();
  }

  createLoader() {
    const innerHTML = `<img class="video-loader__icon" src="${VIDEO_LOADER_ICON}" height="50"/>`;
    const classList = "video-loader";
    const dom = {
      ele: "div",
      attributes: {
        class: classList
      },
      styles: {
        position: "absolute",
        left: "50%",
        top: "50%",
        webkitTransform: "translate(-50%,-50%)",
        MozTransform: "translate(-50%,-50%)",
        msTransform: "translate(-50%,-50%)",
        OTransform: "translate(-50%,-50%)",
        transform: "translate(-50%,-50%)",
        zIndex: "1"
      },
      innerHTML
    };
    const element = createDOMElement(dom);
    this.elementRef[VIDEO_LOADER_ELE] = element;
    return element;
  }

  hideVideoLoader() {
    const videoLoaderEle = this.elementRef[VIDEO_LOADER_ELE];
    if (videoLoaderEle) {
      // videoLoaderEle.classList.add("visibility--hidden");
      videoLoaderEle.style.visibility = "hidden";
    }
  }

  //   get isLoaderRequired() {
  //     const bufferEnd = this.currentUI.spn_player_obj.getBufferEnd();
  //     if (bufferEnd && bufferEnd > this.currentUI.spn_player_obj.currentTime()) {
  //       return false;
  //     }
  //     return true;
  //   }

  showVideoLoader() {
    const videoLoaderEle = this.elementRef[VIDEO_LOADER_ELE];
    if (videoLoaderEle) {
      // videoLoaderEle.classList.remove("visibility--hidden");
      videoLoaderEle.style.visibility = "visible";
    }
  }

  destroy() {
    this.currentUI = {};
    // this.elementRef[VIDEO_LOADER_ELE]?.remove();
    this.elementRef = {};
  }
}
