import { getApiUrl, xhr } from "../utils/player.utils";

export const getULD = (config) => {
  const {
    apiOptions,
    xhrOptions: { securityToken }
  } = config;

  const xhrOptions = {
    headers: {
      security_token: securityToken
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    endpoint: "ULD"
  });

  return xhr(url, xhrOptions);
};

export const partnerValidate = (config) => {
  const {
    apiOptions,
    xhrOptions: { partnerToken }
  } = config;

  const xhrOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: {
      partnerToken,
      platform: "web"
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    endpoint: "PARTNERVALIDATE"
  });

  return xhr(url, xhrOptions);
};

export const getProfile = (config) => {
  const {
    apiOptions,
    xhrOptions: {
      appVersion,
      authorization,
      deviceId,
      securityToken,
      sessionId
    }
  } = config;

  const xhrOptions = {
    headers: {
      authorization,
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId,
      "x-via-device": true
    }
  };

  const url = `${getApiUrl({
    ...apiOptions,
    endpoint: "GETPROFILE"
  })}?channelPartnerID=MSMIND`;

  return xhr(url, xhrOptions);
};

export const partnerLogin = (config) => {
  const {
    apiOptions,
    xhrOptions: { deviceType, deviceToken, securityToken, shortToken, uniqueId }
  } = config;

  const xhrOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      security_token: securityToken
    },
    body: {
      country: apiOptions.country,
      dmaId: apiOptions.country,
      deviceDetails: {
        deviceType,
        serialNo: deviceToken
      },
      partnerID: uniqueId,
      partnerToken: shortToken
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    endpoint: "PARTNERLOGIN"
  });

  return xhr(url, xhrOptions);
};

export const getBranding = (config) => {
  const {
    apiOptions,
    xhrOptions: {
      appVersion,
      authorization,
      deviceId,
      securityToken,
      sessionId
    }
  } = config;

  const xhrOptions = {
    headers: {
      authorization,
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId,
      "x-via-device": true
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    endpoint: "BRANDING"
  });

  return xhr(url, xhrOptions);
};

export const getConfigData = (config) => {
  const {
    packageId,
    apiOptions,
    xhrOptions: { appVersion, deviceId, securityToken, sessionId }
  } = config;

  const xhrOptions = {
    headers: {
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId
    }
  };

  let url = getApiUrl({
    ...apiOptions,
    endpoint: "INITIALCONFIG"
  });

  if (packageId) {
    url += `?packageId=${packageId}`;
  }

  return xhr(url, xhrOptions);
};

export const getProfileData = (config) => {
  const {
    apiOptions,
    xhrOptions: {
      appVersion,
      authorization,
      deviceId,
      securityToken,
      sessionId
    }
  } = config;

  const xhrOptions = {
    headers: {
      authorization,
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId,
      "x-via-device": true
    }
  };

  const url = `${getApiUrl({
    ...apiOptions,
    endpoint: "GETPROFILE"
  })}?channelPartnerID=MSMIND`;

  return xhr(url, xhrOptions);
};

export const getDictionaryData = (config) => {
  const {
    apiOptions,
    xhrOptions: { appVersion, deviceId, securityToken, sessionId }
  } = config;

  const xhrOptions = {
    headers: {
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    endpoint: "DICTIONARY"
  });
  return xhr(url, xhrOptions);
};

export const getDetailsData = (config) => {
  const {
    assetId,
    apiOptions,
    xhrOptions: { appVersion, deviceId, securityToken, sessionId }
  } = config;

  const xhrOptions = {
    headers: {
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    assetId,
    endpoint: "DETAIL"
  });
  return xhr(url, xhrOptions);
};

export const getVideourlData = (config) => {
  const {
    assetId,
    apiOptions,
    xhrOptions: {
      appVersion,
      authorization,
      deviceId,
      securityToken,
      sessionId
    }
  } = config;

  const xhrOptions = {
    headers: {
      authorization,
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    assetId,
    endpoint: "VIDEOURL"
  });

  return xhr(url, xhrOptions);
};

export const getBundleData = (config) => {
  const {
    assetId,
    apiOptions,
    xhrOptions: { appVersion, deviceId, securityToken, sessionId }
  } = config;

  const xhrOptions = {
    headers: {
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId
    }
  };

  const url = getApiUrl({
    ...apiOptions,
    assetId,
    endpoint: "BUNDLE"
  });
  return xhr(url, xhrOptions);
};

export const getContinueWatchXdrData = (config) => {
  const {
    contactId,
    apiOptions,
    xhrOptions: {
      appVersion,
      authorization,
      deviceId,
      securityToken,
      sessionId
    }
  } = config;

  const xhrOptions = {
    headers: {
      authorization,
      app_version: appVersion,
      device_id: deviceId,
      security_token: securityToken,
      session_id: sessionId,
      "x-via-device": true
    }
  };

  let url = getApiUrl({
    ...apiOptions,
    endpoint: "GETXDR"
  });
  url += `?contactId=${contactId}&kids_safe=false`;
  return xhr(url, xhrOptions);
};
