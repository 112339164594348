export const SDK_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED"
};

export const EVENT_CALLBACK = {
  SDK_EVENTS: "SDK_EVENTS",
  NATIVE_EVENTS: "NATIVE_EVENTS",
  CUSTOM_EVENTS: "CUSTOM_EVENTS",
  AD_EVENTS: "AD_EVENTS"
};

export const SDK_EVENTS = {
  SPN_MANAGER_INITIALIZED: "SPN_MANAGER_INITIALIZED",
  SDK_INIT_START: "SDK_INIT_START",
  SDK_INIT_END: "SDK_INIT_END",
  SDK_INIT_FAILED: "SDK_INIT_FAILED",
  SDK_PREFETCH_START: "SDK_PREFETCH_START",
  SDK_PREFETCH_END: "SDK_PREFETCH_END",
  PLAY_CONTENT_START: "PLAY_CONTENT_START",
  PLAY_CONTENT_END: "PLAY_CONTENT_END",
  PLAY_CONTENT_FAILED: "PLAY_CONTENT_FAILED",
  CHANGE_CONTENT_START: "CHANGE_CONTENT_START",
  CHANGE_CONTENT_CALL: "CHANGE_CONTENT_CALL",
  CHANGE_CONTENT_END: "CHANGE_CONTENT_END",
  CHANGE_CONTENT_FAILED: "CHANGE_CONTENT_FAILED",
  IFRAME_LOAD_START: "IFRAME_LOAD_START",
  IFRAME_LOAD_COMPLETE: "IFRAME_LOAD_COMPLETE",
  IFRAME_LOAD_FAILED: "IFRAME_LOAD_FAILED",
  INITIALIZE_PLAYER_MANAGER: "INITIALIZE_PLAYER_MANAGER",
  PLAYER_MANAGER_INITIALIZED: "PLAYER_MANAGER_INITIALIZED",
  PLAYER_MANAGER_API_CALL_START: "PLAYER_MANAGER_API_CALL_START",
  PLAYER_MANAGER_API_CALL_END: "PLAYER_MANAGER_API_CALL_END",
  EMIT_ERROR: "EMIT_ERROR",

  PLAYER_LOAD_START: "PLAYER_LOAD_START",
  PLAYER_LOAD_COMPLETE: "PLAYER_LOAD_COMPLETE",
  INITIALIZE_PLAYER: "INITIALIZE_PLAYER",
  ON_LOADED_DATA: "ON_LOADED_DATA",
  FIRST_PLAY: "FIRST_PLAY",
  DESTROY: "DESTROY",
  SDK_READY: "SDK_READY"
};

export const HANDSHAKE_EVENTS = {
  INITIALIZE_PLAYER_MANAGER: "INITIALIZE_PLAYER_MANAGER",
  CHANGE_CONTENT: "CHANGE_CONTENT",
  SEND_EVENT_TO_PLAYER: "SEND_EVENT_TO_PLAYER",
  GET_PLAYER_DATA: "GET_PLAYER_DATA"
};

export const NATIVE_EVENTS = {
  ABORT: "abort",
  CANPLAY: "canplay",
  CANPLAYTHROUGH: "canplaythrough",
  DURATIONCHANGE: "durationchange",
  EMPTIED: "emptied",
  ENDED: "ended",
  ERROR: "error",
  LOADEDDATA: "loadeddata",
  LOADEDMETADATA: "loadedmetadata",
  LOADSTART: "loadstart",
  PAUSE: "pause",
  PLAY: "play",
  PLAYING: "playing",
  PROGRESS: "progress",
  RATECHANGE: "ratechange",
  RESIZE: "resize",
  SEEKED: "seeked",
  SEEKING: "seeking",
  STALLED: "stalled",
  SUSPEND: "suspend",
  TIMEUPDATE: "timeupdate",
  VOLUMECHANGE: "volumechange",
  WAITING: "waiting",
  INIT: "init",
  DESTROY: "destroy",
  SEEKBAR_UPDATE: "seekbarupdate",
  FIRST_DURATION_CHANGE: "FIRST_DURATION_CHANGE",
  AUDIO_CHANGE: "AUDIO_CHANGE",
  TEXT_TRACK_CUE_CHANGE: "TEXT_TRACK_CUE_CHANGE"
};

export const CUSTOM_EVENTS = {
  AD_COMING_UP: "AD_COMING_UP",
  BACKWARD_CLICK: "BACKWARD_CLICK",
  BACK_CLICK: "BACK_CLICK",
  CATCHERROR: "CATCHERROR",
  CHANGE_SRC: "CHANGE_SRC",
  CLICKED_OUTSIDE: "CLICKED_OUTSIDE",
  DESTROY: "DESTROY",
  DESTROY_PLAYER: "DESTROY_PLAYER",
  EPISODE_CLICK: "EPISODE_CLICK",
  FIRST_PLAY: "FIRST_PLAY",
  FORWARD_CLICK: "FORWARD_CLICK",
  FULL_SCREEN: "FULL_SCREEN",
  INITIALIZE_AUX_PLAYER: "INITIALIZE_AUX_PLAYER",
  LOADEDDATA: "LOADEDDATA",
  LOADEDMETADATA: "LOADEDMETADATA",
  NEXT_CLICK: "NEXT_CLICK",
  PAUSE_CLICK: "PAUSE_CLICK",
  PLAYBACK_QUALITY: "PLAYBACK_QUALITY",
  PLAY_CLICK: "PLAY_CLICK",
  SEEKED: "SEEKED",
  SEEKING: "SEEKING",
  SWITCH_PLAYER: "SWITCH_PLAYER",
  TIMEDMETADATA: "TIMEDMETADATA",
  TIMEUPDATE: "TIMEUPDATE",
  VIDEO_LANGUAGE_CHANGE: "VIDEO_LANGUAGE_CHANGE",
  VOLUME_CHANGE: "VOLUME_CHANGE",
  WAITING: "WAITING",
  WATCHER_ERROR: "WATCHER_ERROR",
  USER_ACTIVE: "USER_ACTIVE",
  BITRATE_CHANGED: "BITRATE_CHANGED",
  TRIGGER_OLD_EVENTS: "TRIGGER_OLD_EVENTS",
  SDK_INIT: "SDK_INIT",
  VIDEO_FORWARD: "VIDEO_FORWARD",
  VIDEO_REWIND: "VIDEO_REWIND",
  VIDEO_AUDIO_CHANGE: "VIDEO_AUDIO_CHANGE",
  MEDIA_SUBTITLE_AUDIO_CLICK: "MEDIA_SUBTITLE_AUDIO_CLICK",
  SUBTITLE_CHANGE: "SUBTITLE_CHANGE",
  SKIP_CREDIT_CLICK: "SKIP_CREDIT_CLICK",
  ENABLE_CONTROLS: "ENABLE_CONTROLS",
  TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
  ON_FULLSCREEN_CHANGE: "ON_FULLSCREEN_CHANGE",
  SKIP_INTRO_CLICK: "SKIP_INTRO_CLICK",
  FULL_SCREEN_CLICK: "FULL_SCREEN_CLICK",
  PLAY_FROM_BEGINNING: "PLAY_FROM_BEGINNING",
  NEXT_EPISODE_CLICK: "NEXT_EPISODE_CLICK",
  SEASON_TAB_CLICK: "SEASON_TAB_CLICK",
  SKIP_BUTTON_VIEW: "SKIP_BUTTON_VIEW",
  SKIP_BUTTON_CLICK: "SKIP_BUTTON_CLICK",
  NEXT_BUTTON_VIEW: "NEXT_BUTTON_VIEW",
  NEXT_BUTTON_CLICK: "NEXT_BUTTON_CLICK",
  NEXT_CLOSE_CLICK: "NEXT_CLOSE_CLICK",
  BINGE_TRAY_VIEW: "BINGE_TRAY_VIEW",
  VIDEO_SCRUB: "VIDEO_SCRUB",
  UPFRONT_LANGUAGE_SELECT: "UPFRONT_LANGUAGE_SELECT",
  VIDEO_GO_LIVE_CLICK: "VIDEO_GO_LIVE_CLICK",
  PLAY_AGAIN: "PLAY_AGAIN",
  REPORT_AN_ISSUE_SELECT: "REPORT_AN_ISSUE_SELECT",
  REPORT_AN_ISSUE_SUBMIT: "REPORT_AN_ISSUE_SUBMIT",
  SUBTITLE_CUECHANGE: "SUBTITLE_CUECHANGE",
  IDLE_SCREEN_UPDATE: "IDLE_SCREEN_UPDATE",
  FREE_PREVIEW_ENDED: "FREE_PREVIEW_ENDED",
  AD_INIT: "AD_INIT",
  SEEK_BACK: "SEEK_BACK",
  SEEK_FORWARD: "SEEK_FORWARD",
  MUTE: "MUTE",
  QUALITY_CLICK: "QUALITY_CLICK",
  SUBTITLE_CLICK: "SUBTITLE_CLICK",
  SKIP_BUTTON_VISIBLE: "SKIP_BUTTON_VISIBLE",
  STREAM_CONCURRENCY_POPUP_CLICK: "STREAM_CONCURRENCY_POPUP_CLICK",
  STREAM_CONCURRENCY_POPUP: "STREAM_CONCURRENCY_POPUP"
};

export const ADS_EVENTS = {
  ADBREAKENDED: "ADBREAKENDED",
  ADBREAKSTARTED: "ADBREAKSTARTED",
  ADCLICK: "ads-click",
  ADCOMPLETE: "ADCOMPLETE",
  ADCONTENTPAUSEREQUESTED: "content-pause-request",
  ADCONTENTRESUMEREQUESTED: "ads-ad-contentResumed",
  ADENDED: "ad-end",
  ADERROR: "ADERROR",
  ADFIRSTQUARTILE: "ADFIRSTQUARTILE",
  ADIMPRESSION: "ADIMPRESSION",
  ADINIT: "ADINIT",
  ADLOADED: "ADLOADED",
  ADMANAGERLOADED: "ADMANAGERLOADED",
  ADMIDPOINT: "ADMIDPOINT",
  ADPAUSED: "ads-pause",
  ADPERIODENDED: "ADPERIODENDED",
  ADPERIODSTARTED: "ADPERIODSTARTED",
  ADPROGRESS: "ADPROGRESS",
  ADREQUEST: "ADREQUEST",
  ADRESUMED: "ads-resume",
  ADSKIPPABLESTATECHANGED: "ADSKIPPABLESTATECHANGED",
  ADSKIPPED: "ADSKIPPED",
  ADSTARTED: "ads-ad-started",
  ADSTREAMINITIALIZED: "ADSTREAMINITIALIZED",
  ADTHIRDQUARTILE: "ADTHIRDQUARTILE",
  ADVIDEO_CLICKED: "ADVIDEO_CLICKED",
  ADVOLUMECHANGE: "ADVOLUMECHANGE",
  DAI_CUEPOINTS_CHANGED: "DAI_CUEPOINTS_CHANGED",
  DAI_ADSTARTED: "DAI_ADSTARTED",
  DAI_ADLOADED: "DAI_ADLOADED",
  DAI_ADBREAKSTARTED: "DAI_ADBREAKSTARTED",
  DAI_ADBREAKENDED: "DAI_ADBREAKENDED",
  DAI_ADPROGRESS: "DAI_ADPROGRESS",
  DAI_ADCLICK: "DAI_ADCLICK",
  DAI_ADSTREAMINITIALIZED: "DAI_ADSTREAMINITIALIZED",
  DAI_ADCOMPLETE: "DAI_ADCOMPLETE",
  DAI_ADSKIPPABLESTATECHANGED: "DAI_ADSKIPPABLESTATECHANGED",
  DAI_ADSKIPPED: "DAI_ADSKIPPED",
  DAI_ADVIDEO_CLICKED: "DAI_ADVIDEO_CLICKED"
};

export const ADS_CUSTOM_EVENTS = {
  ADS_REQUEST: "ADS_REQUEST",
  ADS_PAUSED: "ADS_PAUSED",
  ADS_RESUMED: "ADS_RESUMED",
  ADS_STARTED: "ADS_STARTED",
  ADS_AD_STARTED: "ADS_AD_STARTED",
  ADS_CUEPOINTS_READY: "ADS_CUEPOINTS_READY"
};

export const PLAYER_CALLBACKS = {
  TOGGLE_PLAY_PAUSE: "TOGGLE_PLAY_PAUSE",
  IS_PLAYING: "IS_PLAYING",
  PLAY: "PLAY",
  PAUSE: "PAUSE",
  SEEK_TO: "SEEK_TO",
  DESTROY: "DESTROY",
  GET_DURATION: "GET_DURATION",
  GET_TOTAL_BUFFER: "GET_TOTAL_BUFFER",
  IS_SUBTITLE_AVAILABLE: "IS_SUBTITLE_AVAILABLE",
  GET_SUBTITLE_LANGUAGES: "GET_SUBTITLE_LANGUAGES",
  IS_SUBTITLE_ENABLED: "IS_SUBTITLE_ENABLED",
  SET_SUBTITLE_ENABLED: "SET_SUBTITLE_ENABLED",
  GET_PLAYBACK_VARIANTS: "GET_PLAYBACK_VARIANTS",
  GET_CURRENT_PLAYBACK_VARIANTS: "GET_CURRENT_PLAYBACK_VARIANTS",
  SET_PLAYBACK_VARIANTS: "SET_PLAYBACK_VARIANTS",
  IS_MULTI_AUDIO_AVAILABLE: "IS_MULTI_AUDIO_AVAILABLE",
  GET_MULTI_AUDIO: "GET_MULTI_AUDIO",
  SET_MULTI_AUDIO: "SET_MULTI_AUDIO",
  SET_SUBTITLE_LANGUAGE: "SET_SUBTITLE_LANGUAGE",
  GET_CURRENT_POSITION: "GET_CURRENT_POSITION",
  SEEK_LAST_TEN_SEC: "SEEK_LAST_TEN_SEC",
  FORWARD: "FORWARD",
  SET_VOLUME: "SET_VOLUME"
};

export const PERFORMANCE_EVENTS = {
  INIT_SDK: "INIT_SDK",
  INIT_SDK_CALLBACK: "INIT_SDK_CALLBACK",
  CREATE_PLAYER: "CREATE_PLAYER",
  IFRAME_LOAD_START: "IFRAME_LOAD_START",
  IFRAME_LOAD_COMPLETE: "IFRAME_LOAD_COMPLETE",
  PLAYER_LOAD_START: "PLAYER_LOAD_START",
  PLAYER_LOAD_COMPLETE: "PLAYER_LOAD_COMPLETE",
  PLAYER_MANAGER_START: "PLAYER_MANAGER_START",
  INITIALIZE_PLAYER: "INITIALIZE_PLAYER",
  ON_LOADED_DATA: "ON_LOADED_DATA",
  FIRST_PLAY: "FIRST_PLAY",
  DESTROY: "DESTROY"
};
