import { SPLASH_CONTAINER } from "../../../constants/ui.constants";

export default class SplashScreen {
  constructor(options) {
    this.config = options;
    this.splashUrl = options.asset_url;
    this.elementRef = {};
    this.elementRef[SPLASH_CONTAINER.REF] = this.create();
  }

  render({ parent }) {
    parent.appendChild(this.elementRef[SPLASH_CONTAINER.REF]);
  }

  // eslint-disable-next-line class-methods-use-this
  create() {
    const element = document.createElement("div");
    // element.style.background = `url(${this.splashAssets.bg_img}) no-repeat center center`;
    // element.style.backgroundSize = "cover";
    element.style.width = "100%";
    element.style.height = "100%";
    element.style.position = "absolute";
    element.style.top = "0";
    element.style.left = "0";
    element.style.zIndex = "99";
    // element.style.display = "none";

    const mediaWrapper = document.createElement("div");
    mediaWrapper.style.width = "100%";
    mediaWrapper.style.height = "100%";
    mediaWrapper.style.display = "flex";
    mediaWrapper.style.alignItems = "center";
    mediaWrapper.style.justifyContent = "center";

    // const media = document.createElement("img");
    // media.src = this.splashAssets.asset_url;
    // media.style.maxWidth = "100%";
    // media.style.maxHeight = "100%";
    if (this.splashUrl.toLowerCase().indexOf(".mp4") !== -1) {
      const media = document.createElement("video");
      media.src = this.splashUrl;
      media.autoplay = true;
      media.loop = true;
      media.style.width = "100%";
      media.style.height = "100%";
      mediaWrapper.appendChild(media);
    } else if (
      this.splashUrl.toLowerCase().indexOf(".jpg") !== -1 ||
      this.splashUrl.toLowerCase().indexOf(".jpeg") !== -1 ||
      this.splashUrl.toLowerCase().indexOf(".png") !== -1 ||
      this.splashUrl.toLowerCase().indexOf(".gif") !== -1
    ) {
      const media = document.createElement("img");
      media.src = this.splashUrl;
      // media.style.width = "100%";
      media.style.height = "100%";
      mediaWrapper.appendChild(media);
    }

    element.appendChild(mediaWrapper);
    return element;
  }

  show() {
    this.elementRef[SPLASH_CONTAINER.REF].style.display = "block";
  }

  hide() {
    this.elementRef[SPLASH_CONTAINER.REF].style.display = "none";
  }

  destroy() {
    // eslint-disable-next-line no-unused-expressions
    this.elementRef[SPLASH_CONTAINER.REF] &&
      this.elementRef[SPLASH_CONTAINER.REF].remove();
  }
}
