import { EVENT_CALLBACK } from "../constants/events.constants";

export default class EventManager {
  constructor() {
    this.sdkEventsSubscribers = [];
    this.playerCallback = this.broadcastSdkEvent;
  }

  subscribeToEvents({
    eventType,
    eventCallback,
    senderName = "",
    isSync = false
  }) {
    switch (eventType) {
      case EVENT_CALLBACK.SDK_EVENTS:
        this.sdkEventsSubscribers.push({
          eventCallback,
          senderName,
          isSync
        });
        break;
    }
  }

  handleSubscriber = (subscriberArray, eventName, eventData) => {
    for (let i = 0; i < subscriberArray.length; i++) {
      if (typeof subscriberArray[i].eventCallback === "function") {
        if (subscriberArray[i].isSync || eventData?.isSync) {
          subscriberArray[i].eventCallback(eventName, eventData, this);
        } else {
          setTimeout(() => {
            subscriberArray[i].eventCallback(eventName, eventData, this);
          }, 0);
        }
      }
    }
  };

  broadcastSdkEvent = (event, eventData) => {
    this.handleSubscriber(this.sdkEventsSubscribers, event, eventData);
  };
}
