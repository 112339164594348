import ERRORS from "../constants/error.constants";
import {
  HANDSHAKE_EVENTS,
  NATIVE_EVENTS,
  PLAYER_CALLBACKS,
  SDK_EVENTS
  // SDK_EVENTS
} from "../constants/events.constants";
import { _console } from "../utils/player.utils";

/**
 * @brief PlayerCallbacks exposes function to tataSDK.
 * @export
 * @class PlayerCallbacks
 */
export default class PlayerCallbacks {
  /**
   * @brief broadcast events to player
   * @param {string} eventName
   * @param {object} eventData
   */
  callPlayerMethod = (eventName, eventData) => {
    try {
      if (this.handshakeChild) {
        this.handshakeChild.call(HANDSHAKE_EVENTS.SEND_EVENT_TO_PLAYER, {
          eventName,
          eventData
        });
      } else {
        const errorObj = ERRORS.SPN104;
        errorObj.errorMessage = "SDK is not ready";
        this.sdkCallback(SDK_EVENTS.EMIT_ERROR, errorObj);
        this.broadcastSdkEvent(SDK_EVENTS.PLAY_CONTENT_FAILED, errorObj);
        // this.playContentCallback(ERRORS.SPN101, {});
        // this.emit("error", ERRORS.SPN101);
      }
    } catch (error) {
      _console.log(error);
    }
  };

  /**
   * @param {string} eventName
   * @param {object} eventData
   * @returns {promise} -data from player
   */
  callPlayerMethodAndGetData = (eventName, eventData) => {
    const event = {
      eventName,
      eventData
    };
    try {
      const data = this.handshakeChild.call(
        HANDSHAKE_EVENTS.GET_PLAYER_DATA,
        event,
        true
      );
      return data;
    } catch (error) {
      _console.error("getPlayerData :: ", error);
      return null;
    }
  };

  /**
   * @brief Callback for player Native events
   * @param {*} { eventName, eventData }
   * @memberof PlayerCallbacks
   */
  playerCallback = ({ eventName, eventData }) => {
    // _console.log("playerCallback :: ", eventName, eventData);
    // if (eventName === "loadstart") {
    //   this.videoLoader.hideVideoLoader();
    // }

    // _console.log("playerCallback :: ", eventName, eventData);
    switch (eventName) {
      case NATIVE_EVENTS.LOADSTART:
        this.playContentCallback(null, {
          success: true
        });
        this.splashScreen.destroy();
        this.videoLoader.hideVideoLoader();
        break;
      case NATIVE_EVENTS.ERROR:
        this.emit("error", eventData);
    }
    if (typeof this.sdkConfig.playerCallback === "function") {
      this.sdkConfig.playerCallback(eventName, eventData);
    }
  };

  /**
   * @brief Callback for player Custom events
   * @param {*} { eventName, eventData }
   * @memberof PlayerCallbacks
   */
  playerCustomCallback = ({ eventName, eventData }) => {
    // _console.log("playerCustomCallback :: ", eventName, eventData);
    if (typeof this.sdkConfig.playerCustomCallback === "function") {
      this.sdkConfig.playerCustomCallback(eventName, eventData);
    }
  };

  /**
   * @brief Callback for player Ad events
   * @param {*} { eventName, eventData }
   * @memberof PlayerCallbacks
   */
  adCallback = ({ eventName, eventData }) => {
    // _console.log("adCallback :: ", eventName, eventData);
    if (typeof this.sdkConfig.adCallback === "function") {
      this.sdkConfig.adCallback(eventName, eventData);
    }
  };

  /**
   * @brief -exposed method -trigger play
   * @memberof PlayerCallbacks
   */
  play = () => {
    this.callPlayerMethod(PLAYER_CALLBACKS.PLAY, {});
  };

  /**
   * @brief -exposed method  -trigger pause
   * @memberof PlayerCallbacks
   */
  pause = () => {
    this.callPlayerMethod(PLAYER_CALLBACKS.PAUSE, {});
  };

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  isPlaying = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.IS_PLAYING, {});

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  getCurrentPosition = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.GET_CURRENT_POSITION, {});

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  getTotalDuration = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.GET_DURATION);

  /**
   * @param {*} eventName
   * @param {*} eventData
   */
  getContentBufferedPosition = (eventName, eventData) => {
    this.callPlayerMethod(eventName, eventData);
  };

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  getTotalBufferedDuration = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.GET_TOTAL_BUFFER, {});

  /**
   * @brief -exposed method -toggele play/pause
   * @memberof PlayerCallbacks
   */
  togglePlayPause = () => {
    this.callPlayerMethod(PLAYER_CALLBACKS.TOGGLE_PLAY_PAUSE, {});
  };

  /**
   * @param {object} eventData
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  seekTo = (eventData) => {
    this.callPlayerMethod(PLAYER_CALLBACKS.SEEK_TO, eventData);
  };

  // stop = (eventName, eventData) => {
  //   this.broadcastPlayerEvent(eventName, eventData);
  // };

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  getPlaybackVariants = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.GET_PLAYBACK_VARIANTS);

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  getCurrentPlaybackVariant = () =>
    this.callPlayerMethodAndGetData(
      PLAYER_CALLBACKS.GET_CURRENT_PLAYBACK_VARIANTS
    );

  /**
   * @param {object} quality
   * @memberof PlayerCallbacks
   */
  setPlaybackVariant = (quality) => {
    this.callPlayerMethod(PLAYER_CALLBACKS.SET_PLAYBACK_VARIANTS, quality);
  };

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  isSubtitleAvailable = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.IS_SUBTITLE_AVAILABLE);

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  getSubtitleLanguages = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.GET_SUBTITLE_LANGUAGES);

  /**
   * @param {string} lang
   * @memberof PlayerCallbacks
   */
  setSubtitleLanguage = (lang) => {
    this.callPlayerMethod(PLAYER_CALLBACKS.SET_SUBTITLE_LANGUAGE, lang);
  };

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  isSubtitleEnabled = () =>
    // this.getPlayerData("TEST");
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.IS_SUBTITLE_ENABLED);

  setSubtitleEnabled = (eventName, eventData) => {
    this.callPlayerMethod(eventName, eventData);
  };

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  isMultiAudioAvailable = () =>
    this.callPlayerMethodAndGetData(
      PLAYER_CALLBACKS.IS_MULTI_AUDIO_AVAILABLE,
      {}
    );

  /**
   * @returns {promise}
   * @memberof PlayerCallbacks
   */
  getMultiAudio = () =>
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.GET_MULTI_AUDIO, {});

  /**
   * @param {string} lang
   * @memberof PlayerCallbacks
   */
  setMultiAudio = (lang) => {
    this.callPlayerMethod(PLAYER_CALLBACKS.SET_MULTI_AUDIO, lang);
  };

  /**
   * @param {object} eventData
   * @memberof PlayerCallbacks
   */
  forward = (eventData) => {
    this.callPlayerMethod(PLAYER_CALLBACKS.FORWARD, eventData);
  };

  // isMultiAudioEnabled = (eventName, eventData) => {
  //   this.broadcastPlayerEvent(eventName, eventData);
  // };

  // setMultiAudioEnabled = (eventName, eventData) => {
  //   this.broadcastPlayerEvent(eventName, eventData);
  // };

  // releasePlayer = (eventName, eventData) => {
  //   this.broadcastPlayerEvent(eventName, eventData);
  // };

  seekToLastTenSec = () => {
    this.callPlayerMethodAndGetData(PLAYER_CALLBACKS.SEEK_LAST_TEN_SEC, {});
  };

  /**
   * @param {string} volume
   * @memberof PlayerCallbacks
   */
  setVolume = (volume) => {
    let vol = volume;
    if (vol === "mute") {
      vol = 0;
    } else {
      vol = Number(vol);
    }
    this.callPlayerMethod(PLAYER_CALLBACKS.SET_VOLUME, vol);
  };

  /**
   * @brief -exposed method -destroysPlayer
   * @memberof PlayerCallbacks
   */
  destroyPlayer = () => {
    this.elementRef = {};
    this.callPlayerMethod(PLAYER_CALLBACKS.DESTROY, {});
  };
}
